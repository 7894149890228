<template>
  <div
    class="border border-gray-300 border-solid text-lg lg:text-sm rounded-full w-full box-border flex items-center space-x-2 cursor-pointer px-3 py-1 bg-white hover:border-gray-400 transition-colors"
    @click="emit('click')"
  >
    <MagnifyingGlassIcon class="size-4" />
    <div class="flex-1">Search</div>
    <div
      class="text-xs border px-[5px] py-[1px] rounded-lg border-gray-300 !-mr-2 hidden lg:block"
    >
      {{ formatKeybind("$mod+k") }}
    </div>
  </div>
</template>

<script lang="ts" setup>
import MagnifyingGlassIcon from "@/icons/heroicons/magnifying-glass-micro.svg";
import { formatKeybind } from "@/lib/hotkeys";

const emit = defineEmits(["click"]);
</script>
