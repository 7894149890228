<template>&nbsp;</template>

<script lang="ts" setup>
import Noty from "noty";
import { onMounted, onUnmounted, ref, watch } from "vue";

import type { components as OpenAPI } from "@/autogen/openapi";
import useInterval from "@/lib/interval";
import { useStore as useBulkActionsStore } from "@/store/stores/bulk_actions";

import { generateBulkActionConfiguration } from "./lib";

const props = defineProps<{
  bulkAction: OpenAPI["schemas"]["BulkAction"] & {
    onSuccess?: () => void;
  };
}>();

const notification = ref<Noty>(
  new Noty({
    text: "",
    type: "success",
    progressBar: false,
    timeout: false,
  })
);

const store = useBulkActionsStore();

watch(
  props.bulkAction,
  (newBulkAction) => {
    const config = generateBulkActionConfiguration({})[newBulkAction.type];
    notification.value.setText(
      `${config.label}${
        (newBulkAction.metadata.ids === undefined
          ? []
          : (newBulkAction.metadata.ids as string[])
        ).length > 1
          ? "s"
          : ""
      }...`,
      true
    );
  },
  {
    immediate: true,
  }
);

useInterval(() => {
  store.retrieveBulkAction(props.bulkAction.id);
}, 1000);

onMounted(() => {
  notification.value.show();
});

onUnmounted(() => {
  notification.value.close();
  if (props.bulkAction.onSuccess !== undefined) {
    props.bulkAction.onSuccess();
  }
});
</script>
