<template>
  <component :is="!context.isMobile ? DropdownMenuItem : DrawerClose" as-child>
    <RouterLink
      :to="props.to"
      :class="[
        'group/item',
        'relative flex shrink-0 cursor-pointer select-none items-center gap-3 self-stretch rounded-md border-none px-3 text-left text-sm text-current transition-colors duration-75 hover:bg-gray-700',
        !context.isMobile && 'h-7',
        context.isMobile && 'h-9',
        props.variant === 'default' && 'hover:bg-gray-700',
        props.variant === 'danger' && 'hover:bg-red-600',
      ]"
    >
      <slot v-if="slots.icon" name="icon" />
      <component
        :is="iconMappingMicro[props.icon]"
        v-else-if="props.icon"
        :class="[
          'size-4 shrink-0 transition-colors',
          props.variant === 'default' && 'text-gray-400',
          props.variant === 'danger' &&
            'text-gray-400 group-hover/item:text-white',
        ]"
      />

      <span class="min-w-0 grow break-words">
        {{ props.label }}
      </span>
    </RouterLink>
  </component>
</template>

<script setup lang="ts">
import { DropdownMenuItem } from "radix-vue";
import { DrawerClose } from "vaul-vue";
import { RouterLink } from "vue-router";

import { iconMappingMicro, MappedIconMicro } from "@/icons/icon-mapping-micro";
import { RouteLocation } from "@/router/types";

import { inject } from "./context";

const context = inject();

const props = withDefaults(
  defineProps<{
    label: string;
    to: RouteLocation;
    icon?: MappedIconMicro;
    variant?: "default" | "danger";
  }>(),
  {
    variant: "default",
  }
);

const slots = defineSlots<{
  icon?(): unknown;
}>();
</script>
