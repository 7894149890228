<template>
  <div class="@container">
    <fieldset
      class="border-none m-0 p-0 grid grid-cols-1 @lg:grid-cols-2 @xl:grid-cols-4 gap-2"
    >
      <div
        v-for="option in options"
        :key="option.id"
        :class="{
          'border border-gray-300 rounded-lg p-2 flex flex-col justify-center cursor-default text-center hover:bg-blue-50 hover:border-blue-400 transition-colors relative only:col-span-2': true,
          '!border-blue-400 bg-blue-100 hover:bg-blue-200': modelValue.includes(
            option.id
          ),
          'opacity-50 !cursor-not-allowed': option.disabled,
        }"
        @click="option.disabled ? null : handleUpdate(option.id)"
      >
        <div class="font-bold text-sm whitespace-nowrap truncate">
          {{ option.name }}
        </div>
        <div v-if="option.description" class="text-xs text-gray-500 pr-8">
          <Markdown>{{ option.description }}</Markdown>
        </div>
        <input
          type="checkbox"
          class="absolute right-2 rounded-full border-gray-300"
          :checked="modelValue.includes(option.id)"
        />
      </div>
    </fieldset>
  </div>
</template>

<script setup lang="ts">
import Markdown from "../Markdown";

type Option = {
  id: string;
  name: string;
  description?: string;
  disabled?: boolean;
};

const props = defineProps<{
  options: Option[];
  modelValue: string[];
  radio?: boolean;
}>();

const emit = defineEmits<{
  (e: "update:modelValue", value: string[]): void;
}>();

const handleUpdate = (option_id: string) => {
  if (props.radio) {
    emit("update:modelValue", [option_id]);
  } else if (props.modelValue.includes(option_id)) {
    emit(
      "update:modelValue",
      props.modelValue.filter((option) => option !== option_id)
    );
  } else {
    emit("update:modelValue", [...props.modelValue, option_id]);
  }
};
</script>
