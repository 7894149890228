<template>
  <component :is="!context.isMobile ? DropdownMenuItem : DrawerClose" as-child>
    <button
      :disabled="props.disabled"
      :class="[
        'group/item',
        'relative flex shrink-0 cursor-pointer select-none items-center gap-3 self-stretch rounded-md border-none px-3 transition-colors duration-75',
        'text-left text-sm',
        !context.isMobile && 'h-7',
        context.isMobile && 'h-9',
        props.premium && !props.disabled && 'opacity-50',
        props.disabled && 'opacity-50 cursor-not-allowed pointer-events-none',
        defaultedVariant === 'default' && 'hover:bg-gray-700',
        defaultedVariant === 'accent' && 'bg-blue-500 hover:bg-blue-600',
        defaultedVariant === 'danger' && 'hover:bg-red-600',
      ]"
    >
      <slot v-if="slots.icon" name="icon" />
      <component
        :is="iconMappingMicro[props.icon]"
        v-else-if="props.icon"
        :class="[
          'size-4 shrink-0 transition-colors',
          defaultedVariant === 'default' && 'text-gray-400',
          defaultedVariant === 'accent' && 'text-white',
          defaultedVariant === 'danger' &&
            'text-gray-400 group-hover/item:text-white',
        ]"
      />

      <span class="min-w-0 grow break-words">
        {{ props.label }}
      </span>

      <BoltIcon
        v-if="premium"
        :class="[
          'size-4 shrink-0 transition-colors',
          defaultedVariant === 'default' && 'text-gray-400',
          defaultedVariant === 'accent' && 'text-white',
          defaultedVariant === 'danger' &&
            'text-gray-400 group-hover/item:text-white',
        ]"
      />
    </button>
  </component>
</template>

<script setup lang="ts">
import { DropdownMenuItem } from "radix-vue";
import { DrawerClose } from "vaul-vue";
import { toRef } from "vue";

import BoltIcon from "@/icons/heroicons/bolt-micro.svg";
import { iconMappingMicro, MappedIconMicro } from "@/icons/icon-mapping-micro";

import { inject } from "./context";

const context = inject();

const props = defineProps<{
  label: string;
  icon?: MappedIconMicro;
  /** Convenient property for setting variant="accent", ignored if variant prop is present  */
  active?: boolean;
  premium?: boolean;
  disabled?: boolean;
  variant?: "default" | "accent" | "danger";
}>();

const slots = defineSlots<{
  icon?(): unknown;
}>();

const defaultedVariant = toRef(() => {
  return props.variant ?? (props.active ? "accent" : "default");
});
</script>
