import { type RouteLocation } from "@/router/types";
import { SearchResult } from "@/types/search_result";

type ActionType = "switch_newsletter";

type Action =
  | {
      type: "route";
      route: RouteLocation;
    }
  | {
      type: "action";
      action: ActionType;
      payload: Record<string, string>;
    };

export const generateAction = (result: SearchResult): Action => {
  if (result.type === "tag") {
    return {
      type: "route",
      route: {
        name: "/tags/[id]",
        params: { id: result.obj.id },
      },
    };
  } else if (result.type === "subscriber") {
    return {
      type: "route",
      route: {
        name: "/subscribers/[id]",
        params: { id: result.obj.id },
      },
    };
  } else if (result.type === "webhook") {
    return {
      type: "route",
      route: {
        name: "/webhooks/[id]",
        params: { id: result.obj.id },
      },
    };
  } else if (result.type === "email") {
    return {
      type: "route",
      route: {
        name: "/emails.[id]",
        params: { id: result.obj.id },
      },
    };
  } else if (result.type === "historical_email") {
    return {
      type: "route",
      route: {
        name: "/emails.[id]/history.[[historyId]]",
        params: { id: result.obj.id, historyId: result.obj.history_id },
      },
    };
  } else if (result.type === "automation") {
    return {
      type: "route",
      route: {
        name: "/automations/[id]",
        params: { id: result.obj.id },
      },
    };
  } else if (result.type === "newsletter") {
    return {
      type: "action",
      action: "switch_newsletter",
      payload: {
        newsletter: result.obj.id,
      },
    };
  } else if (result.type === "comment") {
    return {
      type: "route",
      route: {
        name: "/comments/[id]",
        params: { id: result.obj.id },
      },
    };
  } else if (result.type === "request") {
    return {
      type: "route",
      route: {
        name: "/requests/[id]",
        params: { id: result.obj.id },
      },
    };
  } else if (result.type === "invitation") {
    return {
      type: "route",
      route: {
        name: "/settings/team/[id]/",
        params: { id: result.obj.id },
      },
    };
  } else if (result.type === "survey") {
    return {
      type: "route",
      route: {
        name: "/surveys/[id]",
        params: { id: result.obj.id },
      },
    };
  } else {
    return {
      type: "route",
      route: result.obj,
    };
  }
};
