<template>
  <div class="no-underline text-gray-600">
    <li
      :class="[
        'flex cursor-default select-none rounded-xl items-center space-x-2 p-1 px-2 font-medium',
        active && 'bg-gray-100',
      ]"
    >
      <div
        :class="
          'flex flex-none justify-center rounded items-center p-1 ' + classes
        "
      >
        <component
          :is="item.brandIcon"
          v-if="item.type === 'page' && item.brandIcon"
          class="size-4"
        />
        <component :is="icon" v-else-if="icon" class="size-4" />
      </div>
      <div :class="['text-sm m-0', active ? 'text-gray-900' : 'text-gray-700']">
        <span
          v-if="
            !item.text &&
            (item.type === 'email' || item.type === 'historical_email')
          "
          >No subject</span
        >
        <span v-else>
          {{ item.text }}
        </span>
      </div>
      <div
        v-if="item.type === 'historical_email'"
        class="text-sm text-right flex-1 tabular-nums"
      >
        <Datetime :value="item.obj.creation_date" />
      </div>
      <div
        v-if="item.type === 'email'"
        class="text-sm text-right flex-1 tabular-nums"
      >
        <Datetime :value="item.obj.publish_date || item.obj.creation_date" />
      </div>
      <div
        v-if="
          item.type === 'subscriber' ||
          item.type === 'automation' ||
          item.type === 'comment'
        "
        class="text-sm text-right flex-1 tabular-nums"
      >
        <Datetime :value="item.obj.creation_date" />
      </div>
    </li>
  </div>
</template>

<script lang="ts" setup>
import { type Component, computed } from "vue";

import Datetime from "@/components/Layout/ListView/Datetime.vue";
import ArrowTopRightOnSquareIcon from "@/icons/heroicons/arrow-top-right-on-square-micro.svg";
import BookOpenIcon from "@/icons/heroicons/book-open-micro.svg";
import ChartBarIcon from "@/icons/heroicons/chart-bar-micro.svg";
import ChatBubbleLeftRightIcon from "@/icons/heroicons/chat-bubble-left-right-micro.svg";
import ClockIcon from "@/icons/heroicons/clock-micro.svg";
import CodeBracketIcon from "@/icons/heroicons/code-bracket-micro.svg";
import EnvelopeIcon from "@/icons/heroicons/envelope-micro.svg";
import QuestionMarkCircleIcon from "@/icons/heroicons/question-mark-circle-micro.svg";
import SparklesIcon from "@/icons/heroicons/sparkles-micro.svg";
import TagIcon from "@/icons/heroicons/tag-micro.svg";
import UserIcon from "@/icons/heroicons/user-micro.svg";
import { SEARCH_CONTEXT } from "@/search/routes";
import { SearchResult } from "@/types/search_result";

const typeIconMapping: Record<SearchResult["type"], Component> = {
  automation: SparklesIcon,
  comment: ChatBubbleLeftRightIcon,
  documentation: BookOpenIcon,
  email: EnvelopeIcon,
  invitation: UserIcon,
  newsletter: QuestionMarkCircleIcon,
  page: ArrowTopRightOnSquareIcon,
  request: CodeBracketIcon,
  subscriber: UserIcon,
  survey: ChartBarIcon,
  tag: TagIcon,
  webhook: CodeBracketIcon,
  historical_email: ClockIcon,
};

const props = defineProps<{
  item: SearchResult;
  active: boolean;
}>();

const icon = computed(() => {
  if (props.item.type == "page") {
    // @ts-ignore
    const page = SEARCH_CONTEXT[props.item.obj.name];
    if (page) {
      return page.icon;
    }
  }

  return typeIconMapping[props.item.type];
});

const classes = computed(() => {
  if (props.item.type === "page") {
    return "bg-gray-200";
  }
  return "bg-blue-500 text-white";
});
</script>
